import Polyglot from 'node-polyglot';
import * as Sentry from '@sentry/browser';
import PolyglotMissingKey from 'App/Errors/PoliglotMissingKeyError';
import Portuguese from './translations/por.json';
import English from './translations/en.json';
import Spanish from './translations/es.json';
import Romanian from './translations/ro.json';
import Bulgarian from './translations/bg.json';
import Greek from './translations/gr.json';

const polyglot = new Polyglot({
    allowMissing: true,
    onMissingKey: (e) => {
        Sentry.captureException(new PolyglotMissingKey(e));
    },
});

polyglot.extend({
    pt: Portuguese,
    en: English,
    es: Spanish,
    ro: Romanian,
    bg: Bulgarian,
    gr: Greek,
});

export default polyglot;
