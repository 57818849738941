import React, { createContext, useCallback, useEffect, useState } from 'react';
import { usePageLoad } from 'App/Game/hooks';
import SentryConfig from 'App/Helpers/SentryConfig';
import { environment } from 'App/Helpers/enviroments';
import checkAdBlockers from 'App/Helpers/checkAdBlockers';
import CookieService from 'App/Services/CookieService';
import {
    filterGameRelatedAttributes,
    getGameInitValues,
    getUrlParams,
    localStorageValueReset,
} from 'App/Game/helpers';
import { getHash } from 'App/helpers';
import { gaCustomDimensions } from '../config';

const phaseMap = {
    1: 'landing',
    2: 'push',
    3: 'pwa',
    4: 'game',
};

const AppContext = createContext(null);

const AppProvider = ({ config, children }) => {
    const {
        rid,
        domain,
        source,
        sharpstarApiUrl,
        campaignId,
        sentryEnabled,
        buildPath,
        gaTrackId,
        gameAnalyticsKey,
        gameAnalyticsSecret,
    } = config;
    const [urlParams, setUrlParams] = useState({
        rid: undefined,
        refid1: undefined,
        refid2: undefined,
        refid3: undefined,
        hash: undefined,
    });
    const [phase, setPhase] = useState(''); // 'landing','push','pwa','game'
    const [adBlocked, setAdBlocked] = useState(undefined);
    const [sharpstarService, setSharpstarService] = useState(undefined);
    const [leadExtraData, setLeadExtraData] = useState(undefined);
    const [offer, setOffer] = useState(undefined);
    const [gameGA, setGameGA] = useState(undefined);
    const [ga, setGA] = useState(undefined);
    const [gameConfig, setGameConfig] = useState(undefined);
    const [adsConfig, setAdsConfig] = useState(undefined);
    const [offlineMode, setOfflineMode] = useState(false);

    const isPageLoaded = usePageLoad();

    useEffect(() => {
        handleUrlParams();

        const hashChangedHandler = () => {
            const urlHashValue = getHash();

            if (phaseMap[urlHashValue]) {
                updatePhase(phaseMap[urlHashValue]);
            }
        };

        window.addEventListener('hashchange', hashChangedHandler);

        return () =>
            window.removeEventListener('hashchange', hashChangedHandler);
    }, []);

    const initializeSharpstar = useCallback(async () => {
        const { default: SharpstarServiceHandler } = await import(
            'App/Services/Handlers/SharpstarServiceHandler'
        );

        return new SharpstarServiceHandler(
            sharpstarApiUrl,
            source,
            campaignId,
            new CookieService(domain)
        );
    }, [sharpstarApiUrl, source, campaignId, domain]);

    const initializeGameGA = useCallback(
        async (key, secret, buildVersion, dimensions, resources) => {
            const { default: GameAnalyticsService } = await import(
                'App/Services/Analytics/GameAnalytics'
            );

            return new GameAnalyticsService({
                gameKey: key,
                gameSecret: secret,
                environment: environment(),
                build: buildVersion,
                dimensions: dimensions,
                resources: resources,
            });
        },
        []
    );

    const initializeGA = useCallback(async () => {
        const { default: GoogleAnalytics } = await import(
            'App/Services/Analytics/GA4'
        );

        return new GoogleAnalytics(
            true,
            source,
            gaTrackId,
            {},
            { debug_mode: environment() !== 'production' }
        );
    }, [gaTrackId, source]);

    useEffect(() => {
        if (isPageLoaded) {
            SentryConfig.init({
                enabled: sentryEnabled,
                location: document.location.host,
                environment: environment(),
                refid3: urlParams.refid3,
                campaignId,
                source,
            });
            initializeSharpstar().then((sharpstarService) =>
                setSharpstarService(sharpstarService)
            );
        }
    }, [isPageLoaded]);

    useEffect(() => {
        if (isPageLoaded) {
            initializeGA().then((gaInstance) => setGA(gaInstance));
        }
    }, [initializeGA, isPageLoaded]);

    useEffect(() => {
        if (isPageLoaded && gameConfig?.buildVersion) {
            initializeGameGA(
                gameAnalyticsKey,
                gameAnalyticsSecret,
                gameConfig?.buildVersion,
                gameConfig?.dimensions,
                gameConfig?.resources
            ).then((gameGAInstance) => setGameGA(gameGAInstance));
        }
    }, [isPageLoaded, gameConfig?.buildVersion]);

    useEffect(() => {
        if (offer) {
            const {
                content: {
                    'build-version': buildVersion,
                    'game-home-screen-id': gameHomeScreenId,
                    'ga-currencies': gaCurrencies,
                    'ga-item-types': gaItemTypes,
                    'ad-provider': adProvider,
                },
            } = offer;

            const { popup, ...rest } = getGameInitValues(offer.content);
            const leadData = filterGameRelatedAttributes(offer.content);

            setLeadExtraData(leadData);

            const localGameHomeScreenId = localStorageValueReset(
                'gameHomeScreenId',
                gameHomeScreenId
            );

            const resources =
                gaCurrencies && gaItemTypes
                    ? {
                          currencies: gaCurrencies.split(','),
                          itemTypes: gaItemTypes.split(','),
                      }
                    : undefined;

            setGameConfig({
                popup,
                buildVersion,
                buildUrl: `${buildPath}${buildVersion}/`,
                main: {
                    ...rest,
                    HomeScreenId: localGameHomeScreenId || rest.HomeScreenId,
                },
                dimensions: gaCustomDimensions,
                ...(resources && { resources }),
            });

            const localAdProvider = localStorageValueReset(
                'adProvider',
                adProvider
            );

            setAdsConfig({ provider: localAdProvider || adProvider });
        }
    }, [offer]);

    const checkIsAdBlocked = () => {
        return new Promise((resolve) => {
            fetch('https://www.google.com', { method: 'HEAD', mode: 'no-cors' })
                .then(() => {
                    checkAdBlockers((adsBlocked) => {
                        setOfflineMode(false);
                        localStorage.setItem('offlineMode', false);
                        setAdBlocked(adsBlocked);
                        resolve(adsBlocked);
                    });
                })
                .catch(() => {
                    setOfflineMode(true);
                    localStorage.setItem('offlineMode', true);
                    resolve(false);
                });
        });
    };

    const handleUrlParams = () => {
        const urlParamsObj = getUrlParams(window.location.href, rid);

        setUrlParams(urlParamsObj);
    };

    const updatePhase = (name) => {
        setPhase(name);
        localStorage.setItem('phase', name);
    };

    const checkPhase = (showPushPhase, showPwaPhase, offlineMode) => {
        const urlHashValue = getHash();
        const phaseFromMap = phaseMap[urlHashValue];

        // If the current URL has a hash, update to this hash phase
        if (phaseFromMap) {
            updatePhase(phaseFromMap);
            return;
        }

        // Retrieve the phase from localStorage or default to 'landing'
        const storedPhase = localStorage.getItem('phase') || 'landing';
        const languageChanged = localStorage.getItem('languageChanged');
        const nextPhase = languageChanged
            ? storedPhase
            : storedPhase === 'landing'
              ? 'landing'
              : showPushPhase && !offlineMode
                ? 'push'
                : showPwaPhase && !offlineMode
                  ? 'pwa'
                  : 'game';

        updatePhase(nextPhase);
    };

    return (
        <AppContext.Provider
            value={{
                offer,
                ga,
                gameGA,
                phase,
                adBlocked,
                urlParams,
                gameConfig,
                adsConfig,
                leadExtraData,
                setOffer,
                setGameConfig,
                setAdsConfig,
                sharpstarService,
                updatePhase,
                checkPhase,
                checkIsAdBlocked,
                campaignId,
                offlineMode,
            }}>
            {children}
        </AppContext.Provider>
    );
};

export { AppProvider, AppContext };
