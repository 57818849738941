import React from 'react';
import polyglot from '../../../../localization';
import playIcon from '../../../../assets/btn-play-icon.svg';
import itemTextCardLeft from '../../../../assets/howplay_text-card-left.svg';
import itemTextCardRight from '../../../../assets/howplay_text-card-right.svg';
import itemFootballTop1920 from '../../../../assets/howplay_img1-1920.png';
import itemFootballCenter1920 from '../../../../assets/howplay_img2-1920.png';
import itemFootballBottom1920 from '../../../../assets/howplay_img3-1920.png';
import LazyImg from 'App/React/_UI/LazyImg';
import './styles.scss';

const HowPlay = ({ lang, onBtnClick }) => {
    return (
        <section className='howplay'>
            <div className='howplay_main wrapper'>
                <div className='container'>
                    <h2>{polyglot.t(`${lang}.howPlay.title`)}</h2>
                    <div className='howplay_items'>
                        <div className='howplay_item'>
                            <div className='howplay_item-img top'>
                                <LazyImg
                                    url={itemFootballTop1920}
                                    offset={70}
                                    width={785}
                                    height={594}
                                    alt={polyglot.t(
                                        `${lang}.howPlay.alt.step1`
                                    )}
                                />
                            </div>
                            <div className='howplay_item-description right'>
                                <div className='howplay_item-card'>
                                    <div className='howplay_card-img'>
                                        <LazyImg
                                            url={itemTextCardRight}
                                            offset={70}
                                            width={379}
                                            height={505}
                                            alt={polyglot.t(
                                                `${lang}.howPlay.alt.white_back`
                                            )}
                                        />
                                    </div>
                                    <div className='howplay_text-wrapper card1'>
                                        <p>
                                            {polyglot.t(
                                                `${lang}.howPlay.scoreGoal`
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='howplay_item'>
                            <div className='howplay_item-img-left'>
                                <div className='howplay_item-img center'>
                                    <LazyImg
                                        url={itemFootballCenter1920}
                                        offset={70}
                                        width={785}
                                        height={594}
                                        alt={polyglot.t(
                                            `${lang}.howPlay.alt.step2`
                                        )}
                                    />
                                </div>
                            </div>
                            <div className='howplay_item-description left'>
                                <div className='howplay_item-card'>
                                    <div className='howplay_card-img'>
                                        <LazyImg
                                            url={itemTextCardLeft}
                                            offset={70}
                                            width={379}
                                            height={505}
                                            alt={polyglot.t(
                                                `${lang}.howPlay.alt.white_back`
                                            )}
                                        />
                                    </div>
                                    <div className='howplay_text-wrapper card2'>
                                        <p>
                                            {polyglot.t(
                                                `${lang}.howPlay.controlBall`
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='howplay_item' id='how_play_visible'>
                            <div className='howplay_item-img bottom'>
                                <LazyImg
                                    url={itemFootballBottom1920}
                                    offset={70}
                                    width={785}
                                    height={594}
                                    alt={polyglot.t(
                                        `${lang}.howPlay.alt.step3`
                                    )}
                                />
                            </div>
                            <div className='howplay_item-description right'>
                                <div className='howplay_item-card'>
                                    <div className='howplay_card-img'>
                                        <LazyImg
                                            url={itemTextCardRight}
                                            offset={70}
                                            width={379}
                                            height={505}
                                            alt={polyglot.t(
                                                `${lang}.howPlay.alt.white_back`
                                            )}
                                        />
                                    </div>
                                    <div className='howplay_text-wrapper card3'>
                                        <p>
                                            {polyglot.t(
                                                `${lang}.howPlay.collect`
                                            )}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='action wrapper'>
                <div className='container'>
                    <p className='action_title info_block'>
                        <span>{polyglot.t(`${lang}.howPlay.subtitle1`)}</span>
                        <span className='highlight'>
                            {polyglot.t(`${lang}.howPlay.subtitle2`)}
                        </span>
                        <span>{polyglot.t(`${lang}.howPlay.subtitle3`)}</span>
                    </p>
                    <button className='main-btn' onClick={() => onBtnClick()}>
                        {polyglot.t(`${lang}.button`)}
                        <img
                            src={playIcon}
                            alt={polyglot.t(`${lang}.howPlay.alt.btn_play`)}
                            width={20}
                            height={23}
                        />
                    </button>
                </div>
            </div>
        </section>
    );
};

export default HowPlay;
