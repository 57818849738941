import React, { createContext, useState, useEffect, useRef } from 'react';
import { Workbox } from 'workbox-window';
import { consoleInfoLog } from 'App/Helpers/consoleInfoLog';

const SWContext = createContext(null);

const SWProvider = ({ children, swUrl = '/sw.js', scope = '/' }) => {
    const [swUpdate, setSwUpdate] = useState(false);
    const [swReady, setSwReady] = useState(false);
    const wb = useRef(null);

    const handleSWUpdate = () => {
        if (wb.current) {
            wb.current.addEventListener('controlling', () => {
                window.location.reload();
            });

            setSwUpdate(false);
            wb.current.messageSkipWaiting();
        }
    };

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            window.addEventListener('load', function () {
                wb.current = new Workbox(swUrl);

                const showUpdateButton = () => setSwUpdate(true);
                wb.current.addEventListener('waiting', showUpdateButton);

                wb.current
                    .register({ scope })
                    .then((registration) => {
                        const swInstance =
                            registration.installing ||
                            registration.waiting ||
                            registration.active;

                        if (swInstance) {
                            if (swInstance.state === 'activated')
                                setSwReady(true);
                            else {
                                swInstance.addEventListener(
                                    'statechange',
                                    () => {
                                        if (swInstance.state === 'activated') {
                                            setSwReady(true);
                                            consoleInfoLog(
                                                `Worker state changed to: ${swInstance.state}`,
                                                'transparent',
                                                '#fff'
                                            );
                                        }
                                    }
                                );
                            }
                        }

                        consoleInfoLog(
                            `Service Worker registered with scope: ${registration.scope}`,
                            'transparent',
                            '#fff'
                        );
                    })
                    .catch((error) => {
                        consoleInfoLog(
                            `Service Worker registration failed: ${error}`,
                            'transparent',
                            '#fff'
                        );
                    });
            });
        }
    }, [swUrl, scope]);

    return (
        <SWContext.Provider value={{ swUpdate, handleSWUpdate, swReady }}>
            {children}
        </SWContext.Provider>
    );
};

export { SWContext, SWProvider };
