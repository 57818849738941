import Polyglot from 'node-polyglot';
import * as Sentry from '@sentry/browser';
import PolyglotMissingKey from 'App/Errors/PoliglotMissingKeyError';

const polyglot = new Polyglot({
    allowMissing: true,
    onMissingKey: (e) => {
        Sentry.captureException(new PolyglotMissingKey(e));
    },
});

polyglot.extend({
    'en': {
        form_errors: {
            email_address_exists_error:
                'Oops...Your email already exists in our records. Please try again using a different email.',
            invalid_email_disposable:
                'We cannot accept this email, please use a different email address.',
            invalid_email_reason_rejected:
                'Oops...Your email seems to be incorrect, please check for any errors and try again',
            invalid_email_reason_unknown:
                'Something went wrong, please change email or try again',
            invalid_email_role:
                'We cannot accept this email, please use a different email address.',
            invalid_email_w_suggestion: 'Oops... Did you mean %{suggestion}',
            checkbox_error: 'Please agree to continue',
            email_required_error: 'Your email is required to continue',
            email_not_valid_error: 'Please enter a valid email address',
            name_not_valid_length_error: 'Please enter your full name',
            name_has_specials_or_numbers:
                'Please use only letters without special characters and numbers',
            name_validation_failed: 'Please enter a valid name',
        },
        modals_slug: {
            about_us: 'about-us',
            privacy_policy: 'privacy-policy',
            terms: 'terms',
            closeBtn: 'Close',
        },
        footer_links: {
            about_us: 'About us',
            privacy_policy: 'Privacy Policy',
            terms: 'Terms of Use',
        },
        page404: {
            errorNum: '404',
            mainHeader1: 'oh!',
            mainHeader2: 'Something went wrong here',
            subHeader: 'Take me back to the homepage',
        },
    },
    'ro': {
        form_errors: {
            email_address_exists_error:
                'Oops ... E-mailul tău există deja în înregistrările noastre. Încerca din nou folosind un e-mail diferit',
            invalid_email_disposable:
                'Nu putem accepta acest e-mail, așa că te rugăm să utilizezi o altă adresă de e-mail.',
            invalid_email_reason_rejected:
                'Oops ... E-mailul tău pare incorect, verifică dacă există erori și încearcă din nou.',
            invalid_email_reason_unknown:
                'Ceva nu a funcționat cum trebuie, așa că te rugăm să schimbi e-mailul sau să încerci din nou.',
            invalid_email_role:
                'Nu putem accepta acest e-mail, așa că te rugăm să folosești o altă adresă de e-mail.',
            invalid_email_w_suggestion:
                'Oops ... Ai intenționat să %{suggestion}',
            checkbox_error: 'Te rugăm să fii de acord pentru a putea continua',
            email_required_error: 'Este necesar e-mailul tău pentru a continua',
            email_not_valid_error:
                'Te rugăm să introduci o adresă de email validă',
            name_not_valid_length_error:
                'Te rugăm să-ți introduci numele complet',
            name_has_specials_or_numbers:
                'Vă rugăm să folosiți numai litere fără caractere speciale și cifre',
            name_validation_failed: 'Vă rugăm să introduceți un nume valid',
        },
        modals_slug: {
            about_us: 'despre-noi',
            privacy_policy: 'privacy-policy-ro',
            terms: 'terms-of-use-ro',
            closeBtn: 'Închide',
        },
        footer_links: {
            about_us: 'Despre noi',
            privacy_policy: 'Politica de Confidențialitate',
            terms: 'Termenii de Utilizare',
        },
        page404: {
            errorNum: '404',
            mainHeader1: 'OOPS!',
            mainHeader2: 'Ceva nu a funcționat cum trebuie.',
            subHeader: 'Mergi înapoi la pagina principală',
        },
    },
    'jp': {
        form_errors: {
            email_address_exists_error:
                'このメールアドレスは既に使用されています。違うメールアドレスで試してください',
            invalid_email_disposable:
                'このメールアドレスは使用できません。違うメールアドレスで再試行してください',
            invalid_email_reason_rejected:
                'エラー：メールアドレスが間違っています。エラーを確認して、もう一度お試しください。',
            invalid_email_reason_unknown:
                'エラーが発生しました。メールアドレスを変更するか、もう一度お試しください。',
            invalid_email_role:
                'このメールアドレスは使用できません。違うメールアドレスで再試行してください',
            invalid_email_w_suggestion: 'もしかしたら %{suggestion} ですか？ ',
            checkbox_error: '同意して続ける',
            email_required_error: '続行するにはメールアドレスが必要になります',
            email_not_valid_error: '有効なメールアドレスを入力してください',
            name_not_valid_length_error: '氏名を記入してください',
            name_has_specials_or_numbers: '半角英数字で入力してください。',
            name_validation_failed: '有効な名前を入力してください',
        },
        modals_slug: {
            about_us: 'about-us',
            privacy_policy: 'privacy-policy',
            terms: 'terms',
        },
        footer_links: {
            about_us: 'About us',
            privacy_policy: 'プライバシーポリシー',
            terms: '利用規約',
        },
        page404: {
            errorNum: '404',
            mainHeader1: 'oh!',
            mainHeader2: 'Something went wrong here',
            subHeader: 'Take me back to the homepage',
        },
    },
    'kr': {
        form_errors: {
            email_address_exists_error:
                '이 메일 주소는 이미 사용되고 있습니다. 다른 메일 주소를 사용해보십시오.',
            invalid_email_disposable:
                '이 메일 주소는 사용할 수 없습니다. 다른 메일 주소를 사용해보십시오.',
            invalid_email_reason_rejected:
                '오류: 메일 주소가 잘못되었습니다. 오류를 확인하고 다시 시도하십시오.',
            invalid_email_reason_unknown:
                '오류가 발생했습니다. 메일 주소를 변경하거나 다시 시도하십시오.',
            invalid_email_role:
                '이 메일 주소는 사용할 수 없습니다. 다른 메일 주소를 사용해보십시오.',
            invalid_email_w_suggestion: '혹시 %{suggestion} 입니까? ',
            checkbox_error: '동의하고 계속하기',
            email_required_error: '계속하려면 메일 주소가 필요합니다',
            email_not_valid_error: '유효한 메일 주소를 입력하십시오',
            name_not_valid_length_error: '성명을 기입하십시오',
            name_has_specials_or_numbers:
                '특수 문자와 숫자를 제외한 문자만 사용해 주세요',
            name_validation_failed: '유효한 이름을 입력하세요',
        },
        modals_slug: {
            about_us: 'about-us',
            privacy_policy: 'privacy-policy',
            terms: 'terms',
        },
        footer_links: {
            about_us: 'About us',
            privacy_policy: '개인정보 보호정책',
            terms: '이용 규약',
        },
        page404: {
            errorNum: '404',
            mainHeader1: 'oh!',
            mainHeader2: 'Something went wrong here',
            subHeader: 'Take me back to the homepage',
        },
    },
    'it': {
        form_errors: {
            email_address_exists_error: '',
            invalid_email_disposable: '',
            invalid_email_reason_rejected: '',
            invalid_email_reason_unknown: '',
            invalid_email_role: '',
            invalid_email_w_suggestion: '',
            checkbox_error: '',
            email_required_error: '',
            email_not_valid_error: '',
            name_not_valid_length_error: '',
            name_has_specials_or_numbers: '',
            name_validation_failed: '',
        },
        modals_slug: {
            about_us: '',
            privacy_policy: '',
            terms: '',
        },
        footer_links: {
            about_us: '',
            privacy_policy: '',
            terms: '',
        },
        page404: {
            errorNum: '404',
            mainHeader1: 'OPS!',
            mainHeader2:
                'Ci dispiace, ma la pagina che stai cercando non esiste.',
            subHeader: '',
        },
    },
    'pe': {
        modals_slug: {
            terms: 'terminos-de-uso',
            privacy_policy: 'politica-de-privacidad',
        },
        footer_links: {
            terms: 'Términos de uso',
            privacy_policy: 'Política de privacidad',
        },
        footer_texts: {
            top_1_line: 'APLICAN LAS CONDICIONES GENERALES.',
            top_2_line:
                'Los jugadores deben ser mayores de 18 años y estar en Perú.',
            top_3_line: 'Apoyamos el juego responsable.',
            center: 'Casa de Casino es un sitio web de propiedad y operación independiente, no somos un organizador de juegos de azar.',
            copyright:
                '© Copyright. Todos los derechos reservados. CasadeCasino',
        },
    },
    'cl': {
        modals_slug: {
            terms: 'terminos-de-uso',
            privacy_policy: 'politica-de-privacidad',
        },
        footer_links: {
            terms: 'Términos de uso',
            privacy_policy: 'Política de privacidad',
        },
        footer_texts: {
            top_1_line: 'APLICAN LAS CONDICIONES GENERALES.',
            top_2_line:
                'Los jugadores deben ser mayores de 18 años y estar en Chile.',
            top_3_line: 'Apoyamos el juego responsable.',
            center: 'Casa de Casino es un sitio web de propiedad y operación independiente, no somos un organizador de juegos de azar.',
            copyright:
                '© Copyright. Todos los derechos reservados. CasadeCasino',
        },
    },
    'gr': {
        modals_slug: {
            privacy_policy: 'privacy-policy-gr',
            terms: 'terms-of-use-gr',
            closeBtn: 'Κλείσε',
        },
        footer_links: {
            terms: 'Όροι χρήσης',
            privacy_policy: 'Πολιτική απορρήτου',
        },
        footer_texts: {
            top_1_line: '*EΦΑΡΜΟΖΟΝΤΑΙ ΟΙ ΟΡΟΙ ΚΑΙ ΟΙ ΠΡΟΫΠΟΘΕΣΕΙΣ.',
            top_2_line:
                'Οι παίκτες πρέπει να είναι άνω των 21 ετών και να βρίσκονται στην Ελλάδα',
            top_3_line: 'Υποστηρίζουμε τον υπεύθυνο τζόγο.',
            center: 'Το Casinonet.gr είναι ένας ανεξάρτητος ιστότοπος και λειτουργεί, δεν είμαστε χειριστές τυχερών παιχνιδιών. ΑΡΙΘΜΟΣ ΑΔΕΙΑΣ HGC-000072-AFF',
            copyright:
                '© Πνευματικά δικαιώματα. Ολα τα δικαιώματα διατηρούνται. Casinonet.gr',
        },
    },
    'bg': {
        modals_slug: {
            terms: 'usloviya-za-polzvane',
            privacy_policy: 'politika-za-poveritelnost',
            closeBtn: 'Близо',
        },
        footer_links: {
            terms: 'Условия за ползване',
            privacy_policy: 'Политика за поверителност',
        },
        footer_texts: {
            top_1_line: 'ПРИЛАГАТ СЕ ОБЩИТЕ УСЛОВИЯ.',
            top_2_line:
                'Играчите трябва да са на възраст над 18 години и да се намират в България.',
            top_3_line: 'Ние подкрепяме отговорната игра на хазартни игри.',
            center: 'Kazino Ekstra е независимо притежаван и управляван уебсайт, ние не сме организатор на хазартни игри.',
            copyright: '© Copyright. Всички права запазени. KazinoEkstra',
        },
    },
    'pt': {
        modals_slug: {
            terms: 'termos-de-uso',
            privacy_policy: 'politica-de-privacidade',
            closeBtn: 'Fechar',
        },
    },
    'es': {
        modals_slug: {
            privacy_policy: 'privacy-policy-es',
            terms: 'terms-of-use-es',
            closeBtn: 'Cerca',
        },
    },
    'ro-casino': {
        form_errors: {
            email_address_exists_error:
                'Oops ... E-mailul tău există deja în înregistrările noastre. Încerca din nou folosind un e-mail diferit',
            invalid_email_disposable:
                'Nu putem accepta acest e-mail, așa că te rugăm să utilizezi o altă adresă de e-mail.',
            invalid_email_reason_rejected:
                'Oops ... E-mailul tău pare incorect, verifică dacă există erori și încearcă din nou.',
            invalid_email_reason_unknown:
                'Ceva nu a funcționat cum trebuie, așa că te rugăm să schimbi e-mailul sau să încerci din nou.',
            invalid_email_role:
                'Nu putem accepta acest e-mail, așa că te rugăm să folosești o altă adresă de e-mail.',
            invalid_email_w_suggestion:
                'Oops ... Ai intenționat să %{suggestion}',
            checkbox_error: 'Te rugăm să fii de acord pentru a putea continua',
            email_required_error: 'Este necesar e-mailul tău pentru a continua',
            email_not_valid_error:
                'Te rugăm să introduci o adresă de email validă',
            name_not_valid_length_error:
                'Te rugăm să-ți introduci numele complet',
            name_has_specials_or_numbers:
                'Vă rugăm să folosiți numai litere fără caractere speciale și cifre',
            name_validation_failed: 'Vă rugăm să introduceți un nume valid',
        },
        modals_slug: {
            about_us: 'despre-noi',
            privacy_policy: 'politica-de-confidentialitate',
            terms: 'termenii-de-utilizare',
            closeBtn: 'Închide',
        },
        footer_links: {
            about_us: 'Despre noi',
            privacy_policy: 'Politica de Confidențialitate',
            terms: 'Termenii de Utilizare',
        },
        page404: {
            errorNum: '404',
            mainHeader1: 'OOPS!',
            mainHeader2: 'Ceva nu a funcționat cum trebuie.',
            subHeader: 'Mergi înapoi la pagina principală',
        },
        footer_texts: {
            top_1_line: 'TERMENII ȘI CONDIȚIILE CARE SE APLICĂ PE SITE',
            top_2_line:
                'Jucătorii trebuie să aibă vârsta de minim 18 ani sau mai mult și să fie localizați în România.',
            top_3_line:
                'Susținem și promovăm jocurile de noroc în mod responsabil.',
            center: 'Casino7 este un site deținut și operat independent. Nu suntem un operator de jocuri de noroc.',
        },
    },
    'bg-casino': {
        form_errors: {
            email_address_exists_error:
                'Опа.. Вашият имейл вече съществува в нашия архив. Моля опитайте отново с друг имейл.',
            invalid_email_disposable:
                'Не можем да приемем този имейл адрес, моля използвайте друг.',
            invalid_email_reason_rejected:
                'Опа.. Вашият имейл изглежда неправилен, моля проверете за грешки и опитайте отново.',
            invalid_email_reason_unknown:
                'Нещо се обърка, моля променете имейла си или опитайте отново.',
            invalid_email_role:
                'Не можем да приемем този имейл, моля въведете друг.',
            invalid_email_w_suggestion:
                'Опа.. Да не би да имахте предвид %{suggestion}.',
            checkbox_error: 'Моля съгласете се, за да продължите.',
            email_required_error: 'Въведете имейл, за да продължите.',
            email_not_valid_error: 'Моля въведете валиден имейл адрес.',
            name_not_valid_length_error: 'Моля въведете цялото си име.',
            name_has_specials_or_numbers:
                'Моля, използвайте само букви без специални символи и цифри.',
            name_validation_failed: 'Моля, въведете валидно име.',
        },
        modals_slug: {
            about_us: 'kazino-ekstra-za-nas',
            terms: 'usloviya-za-polzvane',
            privacy_policy: 'politika-za-poveritelnost',
            closeBtn: 'Близо',
        },
        footer_links: {
            about_us: 'За нас',
            terms: 'Условия за ползване',
            privacy_policy: 'Политика за поверителност',
        },
        footer_texts: {
            top_1_line: 'ПРИЛАГАТ СЕ ОБЩИТЕ УСЛОВИЯ.',
            top_2_line:
                'Играчите трябва да са на възраст над 18 години и да се намират в България.',
            top_3_line: 'Ние подкрепяме отговорната игра на хазартни игри.',
            center: 'Kazino Ekstra е независимо притежаван и управляван уебсайт, ние не сме организатор на хазартни игри.',
        },
    },
});

export default polyglot;
