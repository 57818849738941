import { init, browserTracingIntegration } from '@sentry/browser';

/**
 * Initializes Sentry for error tracking and performance monitoring.
 *
 * @param enabled {boolean}- Flag to enable or disable Sentry.
 * @param id {string} - User identifier to associate with Sentry events.
 * @param source {string} - Source of the event, used as a tag in Sentry.
 * @param campaignId {string} - Campaign identifier, used as a tag in Sentry.
 * @param location {string} - Current location URL to allow in Sentry.
 * @param env {string} - Environment string (e.g., production,local,development staging).
 * @param refid3 {string} - The third referral ID we get from URL is used as a tag in Sentry.
 * @param refid1 {string} - The first referral ID we get from the URL is used as a tag in Sentry.
 * @param refid2 {string} - The second referral ID we get from the URL is used as a tag in Sentry.
 * @param performanceRate {number} - Sample rate for performance monitoring (optional).
 */

export default class SentryConfig {
    static init({
        enabled,
        id,
        source,
        campaignId,
        location,
        environment,
        refid3,
        refid1,
        refid2,
        performanceRate,
    }) {
        if (enabled) {
            init({
                dsn: 'https://5b86359019bf4330803e3e33b36d936e@sentry.io/1415478',
                environment,
                integrations: [browserTracingIntegration()],
                ...(performanceRate && { tracesSampleRate: performanceRate }),
                allowUrls: [
                    /https?:\/\/((udata|lpdev)\.)?sharpstar\.(pro|dev)/,
                    location,
                ],
                denyUrls: [
                    /https:\/\/cdn\.doubleverify\.com\/dv-measurements\d+\.js/,
                    /^https:\/\/cadmus\.script\.ac\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+\.js$/,
                    'https://c.amazon-adsystem.com/aax2/apstag.js',
                    'https://static.cloudflareinsights.com/',
                    'https://securepubads.g.doubleclick.net/',
                    'https://imasdk.googleapis.com/',
                    'https://cdn.flashtalking.com/',
                    'https://cdn.doubleverify.com/',
                    'https://www.googletagmanager.com/gtag/js',
                    // Chrome extensions
                    'chrome-extension://haldlgldplgnggkjaafhelgiaglafanh/admin.js',
                    /extensions\//i,
                    /^chrome:\/\//i,
                    /^chrome-extension:\/\//i,
                ],
                ignoreErrors: [
                    'top.GLOBALS', // Random plugins/extensions
                ],
                initialScope: {
                    tags: {
                        source: source,
                        campaign_id: campaignId,
                        ...(refid1 !== '0' && { refid1: refid1 }),
                        ...(refid2 !== '0' && { refid2: refid2 }),
                        refid3: refid3,
                    },
                    ...(id && { user: { id: id } }),
                },
                beforeSend(event, hint) {
                    event.extra = event.extra || {};

                    if (hint.originalException != null) {
                        // Assign enumerable error properties to extras
                        try {
                            const keys = Object.keys(hint.originalException);
                            if (keys.length) {
                                event.extra.errorProperties = {};
                                keys.forEach((key) => {
                                    event.extra.errorProperties[key] =
                                        hint.originalException[key];
                                });
                            }
                        } catch (error) {
                            console.warn(
                                '[sentry] Failed to assign enumerable error properties to extras',
                                error
                            );
                        }
                    }

                    let exceptions = event.exception;
                    if (exceptions) {
                        let values =
                            exceptions.values[exceptions.values.length - 1];
                        //check if exceptions has values
                        if (values) {
                            let stacktrace = values.stacktrace;
                            //check if exception has values
                            if (stacktrace) {
                                let frames =
                                    stacktrace.frames[
                                        stacktrace.frames.length - 1
                                    ];
                                //check if stacktrace has frames
                                if (frames) {
                                    let fileName = frames.filename;
                                    //check if stacktrace has file name
                                    if (fileName && event.tags) {
                                        // error_source tag to sentry event
                                        event.tags.error_source = fileName;
                                    }
                                }
                            }
                        }
                    }
                    return event;
                },
            });
        }
    }
}
