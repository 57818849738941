import React, { useContext } from 'react';
import polyglot from '../../localization';
import { LanguageContext } from '../../providers';
import './styles.scss';

const SWUpdateModal = ({ handleSWUpdate }) => {
    const { locale } = useContext(LanguageContext);

    return (
        <div className='update-modal'>
            <div className='update-container'>
                <div className='update-modal_content'>
                    <h2 className='update-modal_text'>
                        <span>{polyglot.t(`${locale}.installSW.title1`)}</span>{' '}
                        {polyglot.t(`${locale}.installSW.title2`)}
                    </h2>
                    <button
                        onClick={handleSWUpdate}
                        className='play-button update-modal_button'>
                        {polyglot.t(`${locale}.installSW.installBtn`)}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SWUpdateModal;
